import * as standard from './sources/standard'
import {
  organizeDataSource,
} from '@shein-aidc/utils-data-source-toolkit'
import type { DS_WishListEntry } from '../../types'

export function getSource({ dataSource = {}, appConfigs }: DS_WishListEntry.SourceParams): DS_WishListEntry.APIS {
  return organizeDataSource<DS_WishListEntry.APIS, DS_WishListEntry.DataSource>(
    {
      standard,
    },
    dataSource,
    appConfigs,
  )
}
