<template>
  <!-- 渲染多个（单个？）不同业务组件 -->
  <div
    v-if="list.length"
    class="cccx-comp-container">
    <div
      v-for="(data, index) in list"
      :key="`${data.id}_${index}`">
      <component
        :is="getComponent(data.__cccx_comp || data.__comp_name)"
        :context="context"
        :content="content"
        :scene-data="sceneData"
        :cate-links="cateLinks"
        :prop-data="data"
        :index="index"
        :data-component-name="data.props && data.props.name"
        :is-cccx-component="!!data.__cccx_comp"
        :language="language"
        :brand="brand"
        :is-last="list.length === index + 1"
        :when-visible="isVisibleHydratedComp(data)"
        @base-image-mounted="handleBaseImageMounted"
        @countdown-end="handleCountdownEnd"
      >
        <template
          v-for="(_, name) in $slots"
          #[name]>
          <slot :name="name"></slot>
        </template>
      </component>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import components from './common/comp.js'
import { compMapping } from './common/comp-map.js'
import { handleObjIsFrozen } from './common/utils.js'

interface Props {
  context: Object
  content: any[]
  cateLinks: Object
  sceneData: Object
  language: Object
}

const props = withDefaults(defineProps<Props>(), {
  context: () => ({}),
  content: () => ([]),
  cateLinks: () => ({}),
  sceneData: () => ({}),
  language: () => ({}),
})

const emits = defineEmits(['base-image-mounted', 'countdown-end'])

const brand = computed(() => {
  return props.context?.WEB_CLIENT || 'shein'
})

const list = computed(() => {
  return handleObjIsFrozen(props.content).filter(item => {
    if (!compMapping(item)) return false
    item.__comp_name = compMapping(item)
    return true
  })
})

const handleBaseImageMounted = () => {
  emits('base-image-mounted')
}
const handleCountdownEnd = () => {
  emits('countdown-end')
}

const isVisibleHydratedComp = data => {
  // 基于视口的懒水合组件
  const { __comp_name, styleType, props } = data || {}
  const arr = ['CartIcon']
  if (styleType === 'ONE_IMAGE_COMPONENT' && props?.metaData?.enableFloatDisplayAfterReceive) {
    arr.push('StaticImageCardController')
  }
  return !arr.includes(__comp_name)
}

const getComponent = name => {
  return components[name] || null
}
</script>
