<template>
  <div class="banner-static-image">
    <template v-for="(item, i) in itemList">
      <BaseImg
        v-if="item.image"
        :key="i"
        v-tap="{
          id: 'click_block_main.comp_top-banner',
          data: { item, index: i, compIndex: index },
        }"
        v-expose="{
          id: 'expose_block_main.comp_top-banner',
          data: { item, index: i, compIndex: index },
        }"
        :class="intervalSpacing ? 'banner-static-image__interval-spacing' : ''"
        :brand="brand"
        :placeholder="{
          width: item.image.width,
          height: item.image.height,
        }"
        :ratio="item.image.ratio"
        :img-src="item.image.src"
        :img-design-width="750"
        :ada="item.ada"
        :first-screen="true"
        :comp-src="'static-image'"
        @click="clickItem(item, i)"
        @base-image-mounted="handleBaseImageMounted"
      />
    </template>
  </div>
</template>

<script name="StaticImage" setup lang="ts">
import { computed, inject } from 'vue'
import BaseImg from '../../base/BaseImg.vue'
import { commonProps } from '../../common/common-props.js'
import { useLink } from '@shein-aidc/bs-sdk-ccc-link'
import type { AS_TopBanner } from '../../../../../types'
const { vTap, vExpose } = inject('analysisInstance') as AS_TopBanner.AnalysisInstance

const props = defineProps({
  ...commonProps,
})
const emits = defineEmits(['base-image-mounted'])

const { cccLink, jumpLink } = useLink({
  propData: props.propData,
  sceneData: props.sceneData,
})

const itemList = computed(() => props?.propData?.props?.items || [])
const metaData = computed(() => props.propData?.props?.metaData || {})
const intervalSpacing = computed(() => metaData.value?.intervalSpacing || false)

const handleBaseImageMounted = () => {
  emits('base-image-mounted')
}

const clickItem = (item, i) => {
  const url = cccLink.getFullLink({
    item,
    cateLinks: props.cateLinks,
    compIndex: props.index,
    index: i,
  })

  jumpLink({ url, hrefType: item.hrefType })
}
</script>

<style lang="less">
@vw: 375/100vw;
.banner-static-image {
  display: flex;
  &__interval-spacing {
    margin: 0 4 / @vw;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
