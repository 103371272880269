<template>
  <span
    style="vertical-align: middle; display: inline-block; line-height: 0"
    :style="{
      'font-size': props.size,
    }"
    :aria-label="ariaLabel"
    @click="goPresearch"
  >
    <svg
      style="width: auto; height: 1em"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.34315 5.34315C8.46734 2.21895 13.5327 2.21895 16.6569 5.34315C19.6022 8.2885 19.7708 12.9591 17.1627 16.102L21.4298 20.3692L20.3692 21.4298L16.102 17.1627C12.9591 19.7708 8.28851 19.6022 5.34315 16.6569C2.21895 13.5327 2.21895 8.46734 5.34315 5.34315ZM15.5962 6.40381C13.0578 3.8654 8.94221 3.8654 6.40381 6.40381C3.8654 8.94221 3.8654 13.0578 6.40381 15.5962C8.94221 18.1346 13.0578 18.1346 15.5962 15.5962C18.1346 13.0578 18.1346 8.94221 15.5962 6.40381Z"
        fill="var(--shein-common-header-icon-color, #000)"
      />
    </svg>
  </span>
</template>

<script name="BPreSearchEntry" setup lang="ts">
import { useCommon } from '../../hooks/common'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { AS_PreSearchEntry } from '../../types'

interface PropsType {
  size?: string,
  ariaLabel?: string,
  abtest?: string,
  routeQuery?: object,
  analysisSource?: AS_PreSearchEntry.AnalysisSource,
  extendsReport?: object,
  goPresearchCallback?: Function
}
const props = withDefaults(defineProps<PropsType>(), {
  size: '24px',
  ariaLabel: '',
  abtest: '',
  analysisSource: undefined,
  routeQuery: () => ({
    pageType: 'all',
  }),
  extendsReport: () => ({}),
  goPresearchCallback: () => (() => {}),
})
const appConfigs = useAppConfigs()

const {
  ariaLabel,
  goPresearch,
  exposeReport,
} = useCommon({ appConfigs, props })

defineExpose({
  exposeReport,
})
</script>
