import type { AS_WishListEntry } from '../../../types'

const standard: AS_WishListEntry.AnalysisFuncs = {
  'click_gotowishlist.comp_wish-list-entry': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_wishlist_enter.comp_wish-list-entry': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_top_site_collect.comp_wish-list-entry': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_top_site_collect.comp_wish-list-entry': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
}

export default standard
