import type { DS_WishListEntry, ResponseRedPointStatusData, ResponseCollectCountData } from '../../../types'

export const getRedPointStatus: DS_WishListEntry.APIS_THIS['getRedPointStatus'] = async function () {
  const result = await this.$schttp<ResponseRedPointStatusData>({
    url: '/user/get_wishlist_red_dot',
    headers: {
        'Content-Type': 'multipart/form-data',
      },
  })
  return result.data
}

export const getUserCollectCount: DS_WishListEntry.APIS_THIS['getUserCollectCount'] = async function () {
  const result = await this.$schttp<ResponseCollectCountData>({
    url: '/user/wishlist/getCounts',
    method: 'POST',
    headers: {
        'Content-Type': 'multipart/form-data',
      },
  })
  return result.data
}
