// 红点逻辑：
// 获取本地暂存红点请求过期时间
const getRedpointExpiredTime: (memberId: string) => number = function (memberId: string) {
    return Number(localStorage.getItem(`wishlistRedpointExpired-${memberId}`)) || 0
  }

  // 设置本地暂存红点请求过期时间
  const setRedpointExpiredTime: (memberId : string
    ) => void = function (memberId: string) {
    const expired = new Date().getTime() + 86400000 // 24H内
    localStorage.setItem(`wishlistRedpointExpired-${memberId}`, expired.toString())
  }

  // 检查本地缓存红点请求过期时间
  const checkRedpointExpireTime: (memberId: string) => boolean = function (memberId) {
    const expired = getRedpointExpiredTime(memberId)
    return new Date().getTime() > expired
  }

  // 设置本地暂存红点状态
  const setRedpointStatus: (params:{
    memberId : string
    redpointStatus: number
  }) => void = function (params: {
    memberId : string
    redpointStatus: number
  }) {
    localStorage.setItem(`wishlistRedpointStatus-${params.memberId}`, params.redpointStatus.toString())
  }

  // 获取本地暂存红点状态
  const getRedpointStatus: (memberId : string) => number = function (memberId : string) {
    return Number(localStorage.getItem(`wishlistRedpointStatus-${memberId}`)) || 0
  }

export {
  getRedpointExpiredTime,
  setRedpointExpiredTime,
  checkRedpointExpireTime,
  setRedpointStatus,
  getRedpointStatus,
}
