<template>
  <div
    class="base-img"
    v-bind="props"
    :data-img-src="baseCutImgSrc">
    <a
      href="javascript:;"
      :style="{ paddingBottom: customRatio || getImgPlaceholder() }"
      class="base-img__href"
      role="link"
      tabindex="0"
      :aria-label="ada"
      @click="clickImg"
    >
      <img
        v-if="firstScreen || showImmediately"
        :class="[
          'base-img__inner',
          `base-img__${fit}`,
          'fsp-element',
          compSrc ? `fsp__${compSrc}` : '',
        ]"
        :src="baseCutImgSrc"
        :style="getImmediateImageStyle()"
        :alt="alt"
        fetchpriority="high"
      />
      <img
        v-else
        :class="['base-img__inner lazyload', `base-img__${fit}`, compSrc ? `fsp__${compSrc}` : '']"
        :style="{
          objectPosition: position,
          ...raidusStyle,
        }"
        :data-src="imgSrc"
        :src="emptyImg || EmptyNewImg"
        :data-design-width="imgDesignWidth"
        :alt="alt"
        :data-exp="imgDataExpStr"
      />
    </a>
  </div>
</template>

<script name="BaseImg" setup lang="ts">
import { ref, onMounted, inject, computed } from 'vue'
import { imgExpandData2Str } from '@shein-aidc/basis-resource'

const EmptyNewImg =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP89B8AAukB8/71MdcAAAAASUVORK5CYII='


interface Props {
  imgSrc: string
  fit?: string
  position?: string
  placeholder: { width: number; height: number; query: string }
  ratio: number | string
  ada: string
  alt?: string
  brand: string
  imgDesignWidth: number | string
  imgDataExp?: Record<string, unknown>
  firstScreen: boolean
  customRatio?: string
  emptyImg?: string
  specialRadius?: string
  compSrc: string
}

const props = withDefaults(defineProps<Props>(), {
  imgSrc: '',
  fit: 'initial',
  position: 'initial',
  placeholder: () => ({ width: 500, height: 500, query: '' }),
  ratio: 0,
  ada: 'Photo',
  alt: '',
  brand: 'shein',
  imgDesignWidth: 0,
  imgDataExp: () => ({}),
  firstScreen: false,
  customRatio: '',
  emptyImg: '',
  specialRadius: '',
  compSrc: '',
})

const emits = defineEmits(['base-image-mounted', 'click'])

const showImmediately = inject('showImmediately', false)
const cutImg = inject('cutImg', url => url)

const imgDataExpStr = imgExpandData2Str(props.imgDataExp)
const raidusStyle = ref(props.specialRadius ? { borderRadius: props.specialRadius } : {})
const baseCutImgSrc = computed(() => getBaseCutImg())


onMounted(() => {
  emits('base-image-mounted')
})

const getImmediateImageStyle = () => {
  return showImmediately
    ? {
        width: '100%',
        height: '100%',
        'vertical-align': 'top',
        position: 'absolute',
        left: 0,
        objectPosition: props.position,
        ...raidusStyle.value,
      }
    : {
        objectPosition: props.position,
        ...raidusStyle.value,
      }
}

const getBaseCutImg = () => {
  if (!cutImg) {
    return props.imgSrc.replace(/^https?:/, '')
  }

  return cutImg(props.imgSrc, props.imgDesignWidth, props.imgDataExp)
}

const clickImg = event => {
  emits('click', event)
}

const getImgPlaceholder = () => {
  if (props.ratio) {
    return (100 / props.ratio).toFixed(2) + '%'
  } else {
    const { height, width } = props.placeholder
    return ((height / width) * 100).toFixed(2) + '%'
  }
}
</script>

<style lang="less">
.base-img {
  width: 100%;
  position: relative;
  overflow: hidden;

  &__href {
    width: 100%;
    display: block;
  }

  &__inner {
    width: 100%;
    height: 100%;
    vertical-align: top;
    position: absolute;
    left: 0;
  }
  &__contain {
    object-fit: contain;
  }
  &__cover {
    object-fit: cover;
  }
  &__scale-down {
    object-fit: scale-down;
  }
  &__initial {
    object-fit: initial;
  }
  &__inherit {
    object-fit: inherit;
  }
  img[src=''],
  img:not([src]) {
    opacity: 0;
  }
}
</style>
