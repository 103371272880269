<template>
  <a
    class="j-spa-goback"
    href="javascript:;"
    role="button"
    tabindex="0"
    :aria-label="ariaLabel"
    :style="styles"
    @click="handleClickBack"
  >
    <span
      data-v-4d906883=""
      class="sui-icon-common__wrap icon__container__preview__container"
      style="vertical-align: middle; display: inline-block; line-height: 0; font-size: 24px;"
      :style="cssRight && { transform: 'rotate(180deg)' }"
    >
      <svg
        style="width: auto; height: 1em;"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.4871 3.95288L16.513 5.04719L9.09603 12L16.513 18.9529L15.4871 20.0472L6.90344 12L15.4871 3.95288Z"
          fill="var(--shein-common-header-icon-color, #000)"
        />
      </svg>
    </span>
  </a>
</template>

<script name="BBackIcon" setup lang="ts">
// 数据源：
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

const appConfigs = useAppConfigs()

const { cssRight } = appConfigs.$envs || {}
const ariaLabel = appConfigs.$language.BBackIcon?.Back || 'Back'

interface Props {
  isBackHome?: boolean,
  isNeedWindowClose?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  isBackHome: false,
  isNeedWindowClose: false,
})

// 数据源：END

const emits = defineEmits(['goBack'])

const styles = {
  position: 'relative',
  display: 'inline-block',
  'text-decoration': 'none',
  'line-height': 1,
  'z-index': 1,
}

const handleClickBack = async () => {
  let result = undefined

  // 调用方是否监听了 goBack 事件
  emits('goBack', res => {
    result = res
  })

  // 如果返回 false, 说明调用方拦截了返回事件,则不执行后续操作
  if (result === false) {
    return
  }

  // 如果是新开窗口, 返回时则关闭窗口
  if (props.isNeedWindowClose && history.length === 1) {
    window.close()
    return
  }

  const langPath = appConfigs.$envs?.langPath
    if (props.isBackHome || (location.pathname.indexOf('/shop-multiple-sets-') > -1 && (document.referrer && document.referrer.indexOf('shein') == -1))) { // 站外落地页为shoplook
      location.href = langPath
    } else {
      setTimeout(function () {
        history.back()
      }, 100)
    }
}
</script>
