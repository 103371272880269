<!-- 翻页效果 倒计时组件 -->
<template>
  <div :class="['vue-countdown-component', { ie: isIE }]">
    <template
      v-for="(item, index) in timeArray"
      :key="index">
      <div
        :class="['time-box']"
        :style="itemStyle">
        <!-- 顶部蒙层 -->
        <div class="mask"></div>
        <!-- 底层基础div -->
        <div class="base">
          <span>{{ item }}</span>
          <div class="base-b">
            {{ timeArrayT[index] }}
          </div>
        </div>
        <!-- 翻页动画div -->
        <div
          :class="['face', { anime: isAnimate[index] }]"
          @animationend="onAnimateEnd(index)">
          {{ timeArrayT[index] }}
        </div>
        <div :class="['back', { anime: isAnimate[index] }]">
          {{ item }}
        </div>
      </div>
      <!-- 文字 -->
      <div
        v-if="isTimeUnitShow(index)"
        :key="`unit-${index}`"
        class="time-unit"
        :style="{ lineHeight: itemStyle.lineHeight, ...signStyle }"
      >
        {{ setTimeUnit(index) }}
      </div>
    </template>
  </div>
</template>

<script name="CountdownFlipper" setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'

interface Props {
  timeObj: Object
  itemStyle: Object
  signStyle: Object
  type: number
  timeUnit: string[]
}

const props = withDefaults(defineProps<Props>(), {
  timeObj: () => ({}), // 倒计时对象
  itemStyle: () => ({}),
  signStyle: () => ({}), // 非数字框样式
  type: 4, // 时间精度 4-日时分秒 3-时分秒 2-分秒 1-秒
  timeUnit: () => ([]), // 数字框之间的文字
})

const isIE = ref(false)
const stepValue = ref(1)
const timeArray = ref(new Array(props.type).fill('00'))
const timeArrayT = ref(new Array(props.type).fill('00'))
const isAnimate = ref(new Array(props.type).fill(false))

const arr = computed(() => {
  const length = timeArray.value.length
  const step = stepValue.value
  const temp = [length - 1, length - step - 1, length - step * 2 - 1, length - step * 3 - 1]
  temp.length = props.type > 1 ? props.type : 1
  return temp
})

watch(
  () => timeArray.value,
  (newV, oldV) => {
    const diff = []
    newV.forEach((value, index) => {
      if (value !== oldV[index]) {
        diff.push({ value, index })
        isAnimate.value[index] = true
        isAnimate.value = [...isAnimate.value]
      }
    })
    diff.forEach(item => {
      timeArrayT.value[item.index] = item.value
      timeArrayT.value = [...timeArrayT.value]
    })
  },
)

watch(
  () => props.timeObj,
  () => {
    start()
  },
  { deep: true },
)

const start = () => {
  const { D, H, M, S } = props.timeObj || {}
  let arr = []
  const type = Number(props.type)
  // 不分开
  type >= 4 && arr.push(String(D).padStart(2, '0'))
  type >= 3 && arr.push(String(H).padStart(2, '0'))
  type >= 2 && arr.push(String(M).padStart(2, '0'))
  arr.push(String(S).padStart(2, '0'))

  timeArray.value = arr
}

const onAnimateEnd = index => {
  isAnimate.value[index] = false
  // 重新赋值，触发响应式更新
  isAnimate.value = [...isAnimate.value]
}

const isTimeUnitShow = index => {
  if (arr.value.includes(index)) {
    if (index === timeArray.value.length - 1 && !props.timeUnit[3]) {
      return false
    }
    return true
  }
  return false
}

const setTimeUnit = index => {
  switch (index) {
    case timeArray.value.length - 1:
      return props.timeUnit[3] || '' // 秒
    case timeArray.value.length - stepValue.value - 1:
      return props.timeUnit[2] || '' // 分
    case timeArray.value.length - stepValue.value * 2 - 1:
      return props.timeUnit[1] || '' // 时
    default:
      return props.timeUnit[0] || '' // 天
  }
}

onMounted(() => {
  if (
    window.ActiveXObject ||
    'ActiveXObject' in window ||
    window.navigator.userAgent.indexOf('Edge') > -1
  ) {
    isIE.value = true
  }
})
</script>

<style lang="less">
/* stylelint-disable */
.vue-countdown-component {
  /* rtl:begin:ignore */
  direction: ltr;
  display: flex;
  @keyframes animate-filp-face {
    0% {
      transform: rotateX(-0.01deg);
      opacity: 1; // 改变opacity 为了QQ浏览器和safari(不支持z-index animate)
    }
    50% {
      opacity: 1;
    }
    51% {
      opacity: 0;
    }
    100% {
      transform: rotateX(-180deg);
      opacity: 0;
    }
  }
  @keyframes animate-filp-back {
    0% {
      transform: rotateX(180deg);
    }
    100% {
      transform: rotateX(-0.01deg);
    }
  }
  &.ie {
    // 为了ie和老版edge（不支持clip-path）
    .base {
      .base-b {
        clip: rect(15px, auto, auto, auto);
      }
    }
    .face {
      clip: rect(auto, auto, 15px, auto);
    }
    .back {
      clip: rect(15px, auto, auto, auto);
    }
  }

  .time-unit {
    padding: 0 0.1067rem;
    color: #222;
    font-size: 0.2667rem;
    white-space: nowrap;
  }
  .time-box {
    position: relative;
    box-sizing: border-box;
    text-align: center;
    perspective: 1.7067rem;
    border-radius: 0.0533rem;
    // padding: 0 2px;
    .mask {
      width: 100%;
      height: 50%;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.1) 0.9%,
        rgba(187, 187, 187, 0.3) 95.58%
      );
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9999;
      transform: translateZ(1px);
      border-radius: 0.0533rem 0.0533rem 0 0;
    }
    &:before {
      content: '';
      position: absolute;
      background: var(--color);
      width: 1px;
      height: 6px;
      top: 50%;
      left: 0;
      margin-top: -3px;
      z-index: -1;
    }
    &:after {
      content: '';
      position: absolute;
      background: var(--color);
      width: 1px;
      height: 6px;
      top: 50%;
      right: 0;
      margin-top: -3px;
      z-index: -1;
    }
    & > div {
      overflow: hidden;
      animation-timing-function: linear;
      animation-duration: 400ms;
      // 为了chrome，需要一个小的角度，否则字体渲染错位
      transform: rotateX(-0.01deg);
      border-radius: 0.0533rem;
      &.base {
        position: relative;
        .base-b {
          position: absolute;
          left: 0;
          bottom: 0;
          border-radius: 0 0 0.0533rem 0.0533rem;
          width: 100%;
          height: 100%;
          background-color: var(--color); // a1比base浅一点点，为了模拟翻页的阴影效果
          clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
        }
      }
      &.face {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color);
        backface-visibility: visible;
        clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
        z-index: 2;
        &.anime {
          animation-name: animate-filp-face;
        }
      }
      &.back {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color); // b0和a1一致
        transform: rotateX(-180deg);
        backface-visibility: visible;
        clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
        &.anime {
          animation-name: animate-filp-back;
        }
      }
    }
  }
}
/* stylelint-enable */
</style>
